.header {

    $h: &;
    position: fixed;
    top: 0;
    width: 100%;
    background: $white;
    z-index: 1000;

    @include mq(md) {
        position: static;
    }

    &__container-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        
        @include mq(md) {
            justify-content: flex-end;
            height: 100px;
            position: relative;

        }
    }

    &__logo {
        @include trans;
        z-index: 2000;
        text-decoration: none;
        
        @include mq(md) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);

        }

        img {
            height: 80px;
            width: auto;
        }
    }

    &__wrapper {
        display: flex;
    }

    &__language {
        @include trans;
        z-index: 2000;
        text-transform: uppercase;
        
        a {
            color: $turquoise;
            text-decoration: none;
            font-size: 18px;
            font-weight: 500;

            &:hover {
                color: lighten($turquoise, 10%);
            }
        }
    }

    &__nav {
        @include trans;

        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-150%);
        width: 100%;
        height: 100vh;
        background: $red;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;

        @include mq(md) {
            position: relative;
            height: 60px;
            transform: translateX(0);
        }
    }

    &__list {
        @include mq(md) {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
    }

    &__item {
        position: relative;

        &:not(:last-child) {
            margin-bottom: 20px;
            
            @include mq(md) {
                margin-bottom: 0;
            }
        }
        
        @include mq(md) {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 20px;
            height: 100%;
        }

        a {
            color: $white;
            font-family: $altFont;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 24px;

            @include mq(md) {
                font-size: 16px;
            }
        }

        &:hover > ul {
            top: 50px;
            height: auto;
            opacity: 1;
            top: 70px;
            pointer-events: all;
            margin-top: 20px;
            
            @include mq(md) {
                margin-top: 0;
            }
        }

        & > ul {
            @include trans;
            opacity: 0;
            height: 0;

            @include mq(md) {
                position: absolute;
                top: 50px;
                background: $red;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    
    &__sublist {
        pointer-events: none;

        @include mq(md) {
            position: relative;
            border-radius: 3px;
            padding: 0 20px;

        }

        &:after {
            content: '';
            position: absolute;
            top: -10px;
            left: 50%;
            transform: translateX(-50%);
            border-bottom: 5px solid $red;
            border-right: 5px solid transparent;
            border-left: 5px solid transparent;
            border-top: 5px solid transparent;
        }
    }

    &__subitem {
        padding: 10px 0;
        @include mq(md) {
            padding: 15px 0;
        }

        &:not(:last-child) {
            border-bottom: 1px solid $altGray;
        }
    }

    &__facebook-button {
        position: fixed;
        bottom: 0;
        background: $blue;
        z-index: 10000;
        border: none;
        height: 30px;
        display: flex;
        align-items: center;
        color: $white;
        padding: 0 20px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        right: 8%;
        cursor: pointer;
        text-decoration: none;

        i {
            margin-right: 10px;
        }
        p {
            margin: 0;
        }
    }
    
    &__hamburguer {
        cursor: pointer;
        width: 25px;
        height: 21px;
        margin-left: 20px;
        z-index: 2000;

        @include mq(md) {
            display: none;
        }

        span {
            @include trans;
            background-color: $turquoise;
            border-radius: 2px;
            content: '';
            display: block;
            width: 100%;
            height: 2px;

            &:nth-child(1) {
                animation:outT 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(2) {
                margin: 7px 0;
                animation:outM 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(3) {
                animation:outBtm 0.6s $easing backwards;
                animation-direction:reverse;
            }
        }
    }

    &.-open {
        #{$h}__hamburguer {
            span {
                background: $white;
            }
            span:nth-child(1) {
                animation:inT 0.6s $easing forwards;
            }
            span:nth-child(2) {
                animation:inM 0.6s $easing forwards;
            }
            span:nth-child(3) {
                animation:inBtm 0.6s $easing forwards;
            }
        }

        #{$h}__nav {
            transform: translateX(0);
        }

        #{$h}__logo,
        #{$h}__language {
            color: $white;

            a {
                color: $white;

            }
        }        
    }
}

//hamburguer animations
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
