.footer {
    font-family: $altFont;
    padding: 40px 0;

    &__logo {
        margin: -20px 0 0;

        img {
            height: 100px;
        }
    }

    &__text {
        margin: 0;
        color: $lightBlack;
    }

    &__subtitle {
        color: $red;
        font-size: 18px;
        margin-top: 40px;

        @include mq(md) {
            margin-top: 0;
        }
    }

    &__list {
        @include mq(md) {
            display: flex;
            flex-wrap: wrap;
        }
    }


    &__item {
        margin-bottom: 10px;
        
        @include mq(md) {
            width: 50%;
        }

        a {
            color: $lightBlack;
            text-decoration: none;
            font-size: 18px;
            padding-left: 20px;
            position: relative;

            &:after {
                content: '';
                width: 6px;
                height: 6px;
                border-right: 1px solid $turquoise;
                border-bottom: 1px solid $turquoise;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%) rotate(-45deg);
            }

            &:hover {
                color: lighten($lightBlack, 20%);
            }
        }
    }

    &__contact-text  {
        color: $lightBlack;
        font-weight: 500;

        a {
            color: $lightBlack;
            text-decoration: none;

            &:hover {
                color: $mainColor;
            }
        }
    }

    &__social {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 40px;

        @include mq(md) {
            width: auto;
            margin-top: 0;
            justify-content: flex-start;
        }


        li {
            margin: 0 10px;
            
            a {
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                border: 1px solid $red;
                border-radius: 50%;

                i {
                    color: $red;
                    font-weight: 900;
                }
            }
        }

    }
}

.-separator {
    width: 100%;
    height: 1px;
    background: $lightestGray;
    margin-bottom: 40px;
}