.home {
    padding-top: 80px;
    overflow: hidden;
    
    @include mq(md) {
        padding-top: 0;
    }
    
    &__hero {
        position: relative;
        background: $black;
        overflow: hidden;
        
        @include mq(md) {
            min-height: 60vh;
        }

        &__image {
            display: none;

            @include mq(md) {
                display: block;
            }

            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: auto;
                opacity: .7;
            }
        }

        &__image-mobile {
            @include mq(md) {
                display: none;
            }

            img {
                position: absolute;
                left: 0;
                top: 0;
                width: auto;
                height: 100%;
                opacity: .7;
            }
        }

        &__col {
            position: relative;
            height: 50vh;

            @include mq(md) {
                height: 80vh;
            }
        }

        &__container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: 100%;
        }

        &__title {
            font-weight: 700;
            color: $white;
            margin: 0;
            text-align: center;
            margin-bottom: 20px;
            font-size: 45px;

            @include mq(md) {
                font-size: 60px;
            }
        }

        &__text {
            text-align: center;
            color: $white;
            margin: 0;
            margin-bottom: 20px;
            font-size: 20px;

            @include mq(md) {
                font-size: 24px;
            }

            br {
                display: none;

                @include mq(md) {
                    display: block;
                }
            }
        }

        &__button {
            height: 50px;
            width: 150px;
            line-height: 50px;
            font-weight: 700;
            color: $white;
            display: block;
            margin-left: auto;
            margin-right: auto;
            background: $red;
            text-align: center;
            text-decoration: none; 
            border-radius: 3px;
        }
    }

    &__interstitial {
        padding: 40px 0;

        &__text {
            font-size: 20px;
        }
    }

    &__accordion {
        width: 100%;
        margin-bottom: 100px;

        &__wrapper {
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
                width: calc(50% - 40px);
                height: 1px;
                background: $altGray;
            }
        }

        &__button {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background: $turquoise;
            border: none;
            position: relative;

            span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                background: $white;
                
                &.-vertical {
                    height: 15px;
                    width: 2px;
                }

                &.-horizontal {
                    width: 15px;
                    height: 2px;
                }
            }
        }
    }

    &__description {
        margin-bottom: 20px;

        &__title {
            font-weight: 700;
            text-align: center;
            margin: 0;
            margin-bottom: 100px;
            font-size: 40px;

            @include mq(md) {
                font-size: 60px;
            }
        }

        &__wrapper {
            width: 100%;
            text-align: center;
            margin-bottom: 80px;
        }

        &__icon {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__subtitle {
            font-size: 24px;
            color: $lightBlack;
        }
    }

    &__houses {
        background: $lightestGray;
        padding: 100px 0;


        &__title {
            color: $turquoise;
            font-size: 40px;
            text-align: center;
            margin: 0;
            margin-bottom: 20px;

            @include mq(md) {
                font-size: 60px;
            }
        }

        &__text {
            margin: 0;
            text-align: center;
            font-size: 18px;
            margin-bottom: 40px;
        }

        &__wrapper {
            position: relative;
            margin-bottom: 30px;
            display: block;


            &:hover {
                .home__houses__image {
                    img {
                        transform: translate3d(-50%, -50%, 0) scale3d(1.05, 1.05, 1.05);
                        opacity: .6;
                    }
                }
            }
        }

        &__image {
            position: relative;
            height: 0;
            padding-bottom: 100%;
            overflow: hidden;
            background: $black;

            img  {
                @include trans($d: .5s);

                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                opacity: .7;
            }
        }

        &__content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);            
            text-align: center;
        }

        &__content-subtitle {
            color: $turquoise;
            font-size: 18px;
            margin: 0;
            font-weight: 700;
        }

        &__content-title {
            @include trans;

            margin: 0;
            font-weight: 700;
            color: $white;
            font-size: 32px;
            text-transform: uppercase;
        }
    }
}
