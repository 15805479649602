.house {

    $h: &;

    padding-top: 80px;
    overflow: hidden;
    
    @include mq(md) {
        padding-top: 0;
    }
    
    &__hero {
        
        @include mq(md) {
            min-height: 10vh;
        }
        
        &__container-top {
            background: $black;
            overflow: hidden;
            position: relative;
        }


        &__image {
            display: none;

            @include mq(md) {
                display: block;
            }

            img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate3d(-50%, -50%, 0);
                width: 100%;
                height: auto;
                opacity: .7;
            }
        }

        &__image-mobile {
            @include mq(md) {
                display: none;
            }

            img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate3d(-50%, -50%, 0);
                width: auto;
                height: 100%;
                opacity: .7;
            }
        }

        &__col {
            position: relative;
            height: 50vh;

            @include mq(md) {
                height: 60vh;
            }
        }

        &__container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: 100%;
        }

        &__title {
            font-weight: 700;
            color: $white;
            margin: 0;
            text-align: center;
            margin-bottom: 20px;
            font-weight: bold;
            
            @include mq(md) {
                font-size: 60px;
            }
        }

        &__text {
            color: $turquoise;
            margin: 0;
            font-weight: 700;
            font-size: 18px;
            text-align: center;
            text-transform: uppercase;
            
            @include mq(md) {
                font-size: 24px;
            }

            br {
                display: none;

                @include mq(md) {
                    display: block;
                }
            }
        }

        &__container-bottom {
            background: $altGray;
            padding: 20px 0;

            @include mq(md) {
                padding: 40px 0;
            }
        }


        &__wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            margin-bottom: 40px;

            @include mq(md) {
                margin-bottom: 0;
            }
        }
        
        &__location-text {
            font-size: 18px;
            margin: 0;

            @include mq(md) {
                font-size: 24px;
            }

            &--dark {
                margin-bottom: 10px;
                font-weight: 700;
                color: $lightBlack;
            }
        }

        &__button {
            height: 50px;
            line-height: 50px;
            font-weight: 700;
            color: $white;
            display: block;
            background: $red;
            text-align: center;
            text-decoration: none; 
            border-radius: 3px;
            width: 100%;
        }
    }

    &__description {
        padding: 80px 0;

        &__title {
            color: $lightBlack;
            font-weight: 700;
            margin: 0;
            margin-bottom: 20px;
            font-size: 24px;        
        }
    
        &__text {
            color: $lightBlack;
            font-size: 17px;
            font-weight: 300;
            margin: 0;
            margin-bottom: 20px;
        }
    
        &__container {
            width: 100%;
            background: $lightestGray;
            margin: 40px 0;

            @include mq(md) {
                margin: 0;
            }
        }
    
        &__wrapper {
            height: 100px;
            display: flex;
            align-items: center;
            width: calc(100% - 55px);
            margin-left: auto;
            margin-right: auto;
    
            &:not(:last-child) {
                border-bottom: 1px solid $lightGray;
            }
        }
    
        &__icon {
            margin-right: 20px;
        }
    
        &__wrapper-text {
            color: $lightBlack;
            font-weight: 300;
            font-size: 18px;
            margin: 0;
            span {
                display: block;
                font-weight: 700;
                margin-bottom: 5px;
            }
        }
        
        &__features {
            padding-bottom: 40px;
            border-bottom: 1px solid $lightGray;
            margin-bottom: 40px;
        }

        &__features-title {
            color: $turquoise;
            font-size: 17px;
            margin: 0;
            margin-bottom: 20px;
            margin-top: 20px;
        }

        &__features-list {
            
            @include mq(md) {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

            }
        }

        &__features-item {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            
            @include mq(md) {
                width: 50%;
            }

            img {
                margin-right: 10px;
                width: 25px;
            }

            p {
                margin: 0;
            }
        }

        &__room-features-item {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            img {
                margin-right: 10px;
                width: 20px;
            }

            p {
                margin: 0;
            }
        }
    }

    &__availability {
        padding: 40px 0;
        background: $lightGray;

        &__title {
            font-weight: 700;
            font-size: 24px;
            color: $lightBlack;
            margin: 0;
            margin-bottom: 40px;            
            text-align: center;
        }

        &__container {
            display: flex;
            margin-bottom: 40px; 
            justify-content: space-between;
            flex-wrap: wrap;

            &:after {
                content: '';
                width: 70px;

                @include mq(md) {
                    display: none;
                }
            }

            @include mq(md) {
                justify-content: center;

            }
        }

        &__room {
            width: 70px;
            height: 90px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
            font-weight: 700;
            font-size: 24px;
            border-radius: 3px;
            margin-bottom: 20px;
            cursor: pointer;
            background: $turquoise;
            
            @include mq(md) {
                margin: 0 10px;
            }

            &:hover,
            &:focus {
                background: transparentize($turquoise, .7)
            }

            &--red {
                background: $red;
            }
        }

        &__legend {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__room-status {
            display: flex;
            margin: 0 15px;

            span {
                display: block;
                width: 15px;
                height: 15px;
                border-radius: 3px;
                margin-right: 10px;
                &.-turquoise {
                    background: $turquoise;
                }

                &.-red {
                    background: $red;
                }
            }
        }

        &__legend-text {
            text-transform: uppercase;
            font-size: 14px;
            margin: 0;
        }
    }

    &__galery {
        padding: 40px 0;
        
        &__title {
            font-weight: 700;
            font-size: 24px;
            color: $lightBlack;
            margin: 0;
            margin-bottom: 40px;            
        }
        
        &__slider {
            padding-bottom: 40px;
            border-bottom: 1px solid $lightGray;
            margin: 0 -10px;
        }

        .slick-slide {
            margin: 0 10px !important;
        }
        
        &__slide {
            overflow: hidden;
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            border-radius: 3px !important;
            
            img {
                border-radius: 3px !important;
                position: absolute;
                max-height: 100%;
                // max-width: 100%;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);

            }
        }
    }

    &__map {
        margin-bottom: 40px;

        &__title {
            font-weight: 700;
            font-size: 24px;
            color: $lightBlack;
            margin: 0;
            margin-bottom: 40px;            
        }
        
        &__container {
            width: 100%;
            height: 0;
            overflow: hidden;
            position: relative;
            padding-bottom: 100%;
            
            @include mq(md) {
                padding-bottom: 50%;
            }

            iframe {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
            }
        }
    }

    &--laprida,
    &--pellegrini,
    &--thames {
        #{$h}__description__features {
            padding-bottom: 0;
            border-bottom: none;
            margin-bottom: 0;
        }
    }

    &--pellegrini,
    &--thames {
        #{$h}__description__room-features--border {
            padding-bottom: 40px;
            border-bottom: 1px solid $lightGray;
            margin-bottom: 40px;
        }
    }
}